
// import axios from "axios";
import React, {useState, useEffect} from 'react';
import Layout from '../Layout';
import configData from "../config.json";
import { Button } from 'react-bootstrap';

const InterestForm = () => {
    const [estate, setEstate] = useState([]);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [fullName, setFullName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [estateName, setEstateName] = useState('');
    const [estateId, setEstateId] = useState(null);
    const [buildingType, setBuildingType] = useState('');
    const [plots, setPlots] = useState();
    const [modeOfPayment, setModeOfPayment] = useState('');



        
    const getEstates = async () => {

        // return axios.get(`${configData.SERVER_URL}/estate/getEstate`).then((response) => {
        //     console.log(response.data.data);
        //     setEstate(response.data.data);
        //   });
        try {
            const response = await fetch(`${configData.SERVER_URL}/estate/getEstate`, {
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
            });
            const responseJson = await response.json();
            if (responseJson.status === "success") {
                setisBtnLoading(false);
                setEstate(responseJson.data);
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false);
                alert(responseJson.message);
            }
        } catch (error) {
            setisBtnLoading(false);
            console.error(error);
        }
    
       }
    

       useEffect(() => {
        getEstates();
        // const interval = setInterval(() => {
        //   getEstates();
        // }, 3000);
    
        // return () => clearInterval(interval);
      }, []);
     
    const submitForm = async (e) => {
        e.preventDefault();
      
        
        setisBtnLoading(true);
  
        return fetch(`${configData.SERVER_URL}/document/interest/form`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body:  JSON.stringify({            
                fullName:fullName,
                dateOfBirth:dateOfBirth,
                phoneNumber:phone,
                address:address,
                state:state,
                city:city,
                email:email,
                estateId:estateId,
                buildingType:buildingType,
                numbOfPlot:plots,
                modeOfPayment:modeOfPayment,
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
    
            if(responseJson.status === "success"){
                setisBtnLoading(false);
                alert(responseJson.message);
                window.location.reload();
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false)
                alert(responseJson.message);
            }
        })
        .catch((error) => {
            setisBtnLoading(false)
            console.error(error);
        });
  
  
  

    }





    return (
        <>

          <Layout>


        <div class="section-1">
	        <div class="container">
	            <div class="row">
                    <div class="col"></div>

                    <div class="col-md-6 mt-5 mb-5">
                             <form  onSubmit={submitForm} method="POST" enctype="multipart/form-data" id="submit" class="text-center border border-light p-5">
                             <input type="hidden" name="_token" value="hMxqRH7ApBjeNKLxbDTBjN8ZBFeTfIPRRgJ7AROm"/>  
                             <p class="h4 mb-4">PROPERTY INTEREST FORM</p>


                                    <div class="form-row mb-4">
                                        <div class="col">
                                            <input type="text" id="defaultRegisterFormFirstName" class="form-control" placeholder="Full Name" name="fullName" onChange={(e)=> setFullName(e.target.value)}/>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                     <div class="col">
                                     <label for="dateofbirth">Date Of Birth</label>
                                         <input type="date" id="dateofbirth" class="form-control" placeholder="Date Of Birth" name="dateOfBirth" onChange={(e)=> setDateOfBirth(e.target.value)}/>                                                    
                                     </div>
                                 </div> 
                                 
                                    <div class="form-row mb-4">
                                        <div class="col">
                                            <input type="tel" id="Phone" class="form-control" placeholder="Phone Number" name="phone" onChange={(e)=> setPhone(e.target.value)}/>
                                        </div>
                                    </div>                                         
                                 
                                    <div class="form-row mb-4">
                                        <div class="col">                                    
                                            <textarea type="text" id="defaultRegisterFormEmail" class="form-control" placeholder="Address" rows="2" name="address" onChange={(e)=> setAddress(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                        <div class="col">
                                            <input type="text" id="defaultRegisterFormFirstName" class="form-control" placeholder="State" name="state" onChange={(e)=> setState(e.target.value)}/>
                                        </div>
                                        <div class="col">
                                            <input type="text" id="defaultRegisterFormFirstName" class="form-control" placeholder="City" name="city" onChange={(e)=> setCity(e.target.value)}/>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                        <div class="col">
                                            <input type="text" id="defaultRegisterFormFirstName" class="form-control" placeholder="Email" name="email" onChange={(e)=> setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                        <div class="col">
                                            <div class="form-group">
                                                  <label for="sel1">Select Property Intrested In:</label>
                                                  <select class="form-control" id="sel1" name="property" onChange={(e)=> setEstateId(e.target.value)}>
                                                    <option selected>Select Estate</option>
                                                    {estate.map(e => (
                                                        <option value={e._id}>{e.name}</option>
                                                        ))}
                                                  </select>
                                                </div>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-2">
                                        <div class="col">
                                            <div class="form-group">
                                                  <label for="sel1">Type of Building</label><br/>
                                                 <div class="form-check-inline">
                                                      <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="building" value="Duplex" onChange={(e)=> setBuildingType(e.target.value)}/>Duplex
                                                      </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                      <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="building" value="Bungalow" onChange={(e)=> setBuildingType(e.target.value)}/>Bungalow
                                                      </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                      <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="building" value="Commercial" onChange={(e)=> setBuildingType(e.target.value)}/>Commercial
                                                      </label>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                        <div class="col">
                                            <input type="text" id="defaultRegisterFormPlots" class="form-control" placeholder="Number of Plots" name="plots"  onChange={(e)=> setPlots(e.target.value)}/>
                                        </div>
                                    </div>
                                 
                                 <div class="form-row mb-4">
                                        <div class="col">
                                            <div class="form-group">
                                                  <label for="sel1">Mode of Payment</label><br/>
                                                 <div class="form-check-inline">
                                                      <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="payment" value="Outright"  onChange={(e)=> setModeOfPayment(e.target.value)}/>Outright
                                                      </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                      <label class="form-check-label">
                                                        <input type="radio" class="form-check-input" name="payment" value="Installment"  onChange={(e)=> setModeOfPayment(e.target.value)}/>Installment
                                                      </label>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                 
                                 
                                 

                <div className="mt-4 mb-4">

                    <Button variant="primary"  className="float-end"  type="submit" disabled={isBtnLoading}>     
                    {isBtnLoading ? (<>Waiting...</>) : (<>Submit Form</>)}
                    </Button>
                 </div>
                                 
                                 
                                 


                                    {/* <button class="btn btn-md btn-color my-4 btn-block waves-effect waves-light" type="submit" disabled={isBtnLoading}>     
                                                   {isBtnLoading ? (<>Submiting</>) : (<>Submit</>)}
                                    </button> */}
                                

                                    {/* <!-- Terms of service -->
                                    <!--<p>By clicking
                                        <em>Submit</em>
                                        <a href="" target="_blank">terms of service</a>
                                     </p>--> */}

                                </form>
                            
                         </div>
                    
                    <div class="col"></div>
                    
                </div>
                
	        </div>
        </div>



          </Layout>
            
        </>
    );
};

export default InterestForm;