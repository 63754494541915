import React, {useState, useEffect}  from 'react';
import {Modal, Row, Col, Accordion } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import configData from "../../config.json";

import styles from "./Style.module.css";
import Layout from '../../Layout';

const EstateDetails = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { data } = state;
    
    // const { pathname } = useLocation();

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);


    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState("");
    const [plan, setPlan] = useState([]);
    const [faq, setFaq] = useState([])



    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [plots, setPlots] = useState();
    const [images, setImages] = useState([])



    const [defaultText, setDefaultText] = useState('Select Property Intrested In:');
    const [estate, setEstate] = useState([]);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [estateName, setEstateName] = useState(data.name);

    
    const getEstates = async () => {
        try {
            const response = await fetch(`${configData.SERVER_URL}/estate/getEstate`, {
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
            });
            const responseJson = await response.json();
            if (responseJson.status === "success") {
                setEstate(responseJson.data);
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false);
                alert(responseJson.message);
            }
        } catch (error) {
            setisBtnLoading(false);
            console.error(error);
        }
    
       }

    const getEstImg = async() => {

        setisBtnLoading(true);

        return axios.get(`${configData.SERVER_URL}/estate/getImages/${data._id}`).then((response) => {
            if(response.data.status === "success"){
                console.log("images data", response.data.data)
                setisBtnLoading(false)
                setImages(response.data.data.images);
            }
            if (response.data.status === "error") {
                setisBtnLoading(false)
                alert(response.data.message);
            }
          });


     }

    const getFAQ = async() => {

        setisBtnLoading(true);

        return axios.get(`${configData.SERVER_URL}/estate/getFaq/${data._id}`).then((response) => {
            console.log(response.data);
            if(response.data.status === "success"){
                setisBtnLoading(false)
                setFaq(response.data.data);
            }
            if (response.data.status === "error") {
                setisBtnLoading(false);
                alert(response.data.message);
            }
          });


     }

            
     
  const getEstatePlan = async() => {

    setisBtnLoading(true);

    return axios.get(`${configData.SERVER_URL}/estate/getEstatePlan/${data._id}`).then((response) => {
      console.log(response.data.data);
        if(response.data.status === "success"){
             setPlan(response.data.data);
             setisBtnLoading(false);
        }
        if (response.data.status === "error") {
            setisBtnLoading(false)
            alert(response.data.message);
        }
      });
   }
    

       useEffect(() => {
            getEstates();
            getEstImg();
            getFAQ();
            getEstatePlan();
        }, []);
     


    const getInTouch = () => {
        setModalShow(true);
    }

    const owlResponsive = {
        0: {
            items: 1,
        },
        450: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 3,
        }
    }

    const submitForm = async () => {
        console.log("Submit")
        
        setisBtnLoading(true);

        return fetch(`${configData.SERVER_URL}/interestForm/addInterestForm`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body:  JSON.stringify({            
                fullName:fullName,
                phone:phone,
                address:address,
                email:email,
                estate:estateName,
                plots:plots
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
    
            if(responseJson.status === "success"){
                setisBtnLoading(false)
                alert(responseJson.message);
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false)
                alert(responseJson.message);
            }
        })
        .catch((error) => {
            setisBtnLoading(false)
            console.error(error);
        });




    }



    return (
        <Layout>

        <section className={styles.sectionOne}>

          <div className={styles.boxOne}>
                
                <div className={styles.estateBoxOne}>
                        <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s"> {data?.name} </h2> 
                  </div>

                  <div className={styles.estateBoxTwo}>

                    {data.pageContent.split('<>').map(page => (
                            <p className={`${styles.paragraphTitle} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">{page}</p>
                                ))}
                  </div>

              </div>
            <div className={styles.boxTwo}>
                {data.isSoldOut === true && <>
                <span className="soldOut mb-4" href="#"> (Sold Out)</span></>}
                <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s" id={styles.textH}>
                    ₦ {data.priceTwo}
                 </h2> 

                <span className={`${styles.dot} ${'wow fadeIn'}`}  data-wow-duration="1.2s"></span>
                <div className={`${styles.lineP} ${'wow fadeInUp'}`}  data-wow-duration="1.5s">
                    <div className={`${styles.line}`}></div>
                </div>
            </div>


        </section>


        <section className={styles.sectionTwo}>

                <div className={styles.secTwoDivOne}>
                    <span className={`${styles.lineTwo} ${'wow fadeInUp'}`} data-wow-duration="0.8"></span>
                </div>
                <div className={styles.secTwoDivTwo}>
                        <h4 className={`${"wow fadeInUp"}`}  data-wow-duration="0.8s">Location</h4>
                        <span className='wow fadeInUp' data-wow-duration="1s">{data?.location}</span>
                    <div className={styles.imgDiv}>

	                 	<OwlCarousel items={3} responsive={owlResponsive} stagePadding={"50"} margin={8} autoplay={false}  loop={false} nav={true} dots={false}>
                         {images && images.length > 0 ?
                                  images.map((img, index) => ( <>

                                    <div>
                                        <img  
                                        // crossorigin="anonymous" 
                                        className="img" src={`${configData.PIC_URL}/Images/${img}`}  id="myImg"/>
                                      </div>  
                                  </>)) 
                                  :<>
                                  </>}
                              </OwlCarousel> 
                     {images.length <= 0 ?
                         <img src="/images/defaultColor.jpg" className="d-inline-block align-top img-fluid wow fadeInUp"  data-wow-duration="1.2s" alt=""/> : null }

                    </div>
                    <div className={styles.imgDivTwo}>
                        <div className={styles.one}>
                        <h4 className={`${"wow fadeInUp"}`}  data-wow-duration="1.2s">Features</h4>
                        </div>

                        <div className="row w-100">
                        {data.features.split(',').map(feat => (
                            <div className="col-md-4 mb-2 wow fadeInUp featD" data-wow-duration="1.2s">
                                 <i className="fa fa-check-circle pop-icons"></i>
                                 <span className={styles.feat}> {feat}</span>
                              </div>
                                        ))}


                              </div>
                      </div>
                  </div>


                </section>


          <section className={styles.sectionThree}>
                <h4 className={`${"wow fadeInUp"}`}  data-wow-duration="1.2s">Payment Plan</h4>
            <div className={styles.sectionTh}>
                
                <div className={styles.sectionThreeDiv}>
                              
                {plan && plan.length > 0 
                        ? plan.map(p => {
                          return <>

                            <div className={`${styles.paymentPlan} ${"wow fadeInUp"}`}  data-wow-duration="0.8s">
                                <h5>{p.title}</h5>
                                 <NumberFormat value={p.amount} displayType={'text'} thousandSeparator={true} prefix={`₦ `} renderText={text => <h5>{text}</h5>} />
                            </div>
                                
                          </>
                        }) : <>
                        <section className="container mt-5">
                        <div className="row justify-content-center align-items-center">
                                <p className={styles.notFound}>Property does not have a payment plan</p>
                            </div>
                          </section>
                              </>}
                    <div className={styles.getInTouch}>
                        
                    </div>

                </div>
                <div className={styles.sectionThreeDivTwo}>
                    <div className={`${styles.touchLine}`}></div>
                    <div onClick={() => getInTouch()} className={styles.btnGetInTouch}>
                      <span className={styles.touchText}>Get in</span>
                      <span className={styles.touchText}>Touch</span>
                     </div>
                 </div>
            </div>


              

           </section>


           <section className={styles.sectionFour}>
           {faq.length > 0 ? <>
             <div id="FAQ">
                <h1 className={`${styles.digital} ${"wow fadeInUp text-center"}`}data-wow-duration="0.8s">FAQs</h1>


                <Accordion flush>
                    {faq && faq.length > 0 ?
                      faq.map((f, index) => ( <>
                    <Accordion.Item eventKey={index}  className={`${"wow fadeInUp"}`} data-wow-duration="0.8s">
                        <Accordion.Header>{f.title}</Accordion.Header>
                        <Accordion.Body>
                        {f.content}
                        </Accordion.Body>
                    </Accordion.Item>
                 
                 </>)) 
                 :<>
                 </>
                 }   
                    </Accordion>


                 </div>
           </>   : null  }

                 

                      

           <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <Row>
            
           <Col lg="1"/>
                <Col lg="10" style={{paddingBottom:"120px"}}>

                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                     Interest Form
                    </Modal.Title>
                 </Modal.Header>



                        <div className="form-floating mt-3">
                            <input placeholder="Your Name" type="text" className="h-auto form-control" id="floatingInput" name="fullName"
                            onChange={(e) => setFullName(e.target.value)}
                            />
                            <label for="floatingInput">Full Name</label>
                        </div>
                        <div className="form-floating mt-3">
                            <input placeholder="Your Name" type="text" className="h-auto form-control" id="floatingInput" name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            />
                            <label for="floatingInput">Email</label>
                        </div>
                        <div className="form-floating mt-3">
                            <input placeholder="Your Phone Number" type="text" className="h-auto form-control" id="floatingInput" name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            />
                            <label for="floatingInput">Phone Number</label>
                        </div>
                        <div class="form-floating mt-3">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"  name="estateName" onChange={(e)=> setEstateName(e.target.value)}>
                                <option selected>{estateName}</option>
                                    {estate.map(e => (
                                        <option value={e.name}>{e.name}</option>
                                        ))}
                            </select>
                            <label for="floatingSelect">Propertie Interested in</label>
                         </div>


                            <div class="form-floating mt-3">   
                                    <textarea type="text" id="defaultRegisterFormEmail" class="form-control" placeholder="Address" rows="2" name="address" onChange={(e)=> setAddress(e.target.value)}></textarea>
                                    <label for="floatingInput">Address</label>
                                </div>

                            <div class="form-floating mt-3">
                                    <input type="text" id="defaultRegisterFormPlots" class="form-control" placeholder="Number of Plots" name="plots" onChange={(e)=> setPlots(e.target.value)}/>
                                    <label for="floatingInput">Number of Plots</label>
                              </div>
                                 


                            {msg !== "" ?   
                          <span style={{color:"#D42323"}}>
                              <strong>{msg}</strong> 
                          </span> 
                                : null}

                  <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                       {loading === false ?       
                               <button onClick={submitForm} type="button" className="mt-4 float-end botton btn btn-primary btn-pm  btn-elrio btn-md" >Submit</button>
                                : <>

                                {/* <ThreeDots 
                                    height="50" 
                                    width="50" 
                                    radius="9"
                                    color="#003399" 
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}/> */}
                                    
                                </>}

                  </div>
                           




                    
                </Col>
           <Col lg="1"/>
 



        </Row>
      </Modal.Body>
    </Modal>

     


           </section>
            
        </Layout>
    );
};

export default EstateDetails;