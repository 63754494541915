
// import axios from "axios";
import React, {useState, useEffect} from 'react';
import Layout from '../Layout';
import configData from "../config.json";
import { useHistory, useParams } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import { useForm } from "react-hook-form";

const RealtorForm = () => {
    const { username } = useParams();
    const { register, handleSubmit, setValue,reset, errors } = useForm();
    const [marketer, setMarketer] = useState([]);
    const [isBtnLoading, setisBtnLoading] = useState(false);



        
    const getReferial = async() => {

        return fetch(`${configData.SERVER_URL}/marketer/getMarketer/${username}`, {
            method: "get",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },

        })
        .then((response) => response.json())
        .then((responseJson) => {
        
            if(responseJson.status === "success"){
                setisBtnLoading(false)
                setMarketer(responseJson.data);
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false)
                console.log(responseJson.message);
            }
        })
        .catch((error) => {
            setisBtnLoading(false)
            console.error(error);
        });
    
       }

       useEffect(() => {
        getReferial();
      }, []);
     
    const submitForm =  async (data) => {

       const formData = new FormData();

       formData.append('profImage', data.profImage[0]);
       formData.append('fullName', data.fullName);
       formData.append('email', data.email);
       formData.append('phoneNumber', data.phoneNumber);
       formData.append('sex', data.sex);
       formData.append('address', data.address);
       formData.append('accountName', data.accountName);
       formData.append('accountNumber', data.accountNumber);
       formData.append('bankName', data.bankName);
    //    formData.append('referralId', marketer._id);

       const referralId = marketer._id;


     setisBtnLoading(true)

         

     try {
       const response = await fetch(`${configData.SERVER_URL}/marketer/registration/${referralId}`, {
         method: "post",
         headers: {
           "x-auth-token":  window.localStorage.getItem("token")
         },
         body: formData,
       });
       const responseJson = await response.json();

       if (responseJson.status === "success") {
             setisBtnLoading(false);
             reset()
             alert(responseJson.message);
       }
       if (responseJson.status === "error") {
             setisBtnLoading(false);
             alert(responseJson.message);
       }
     } catch (error) {
             setisBtnLoading(false);
             console.error(error);
     }
  
  

     }

    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setValue('image', file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };


    return (
        <>

          <Layout>


        <div class="section-1">
	        <div class="container">
	            <div class="row">
                    <div class="col"></div>

                    <div class="col-md-6 mt-5 mb-5">
                                                    
        <form onSubmit={handleSubmit(submitForm)} className="pt-3"  enctype="multipart/form-data" id="submit">

         <div className="adminForm">

           <p class="h4 mb-4">MARKETING FORM</p>

           <div className="col-md-12 d-flex justify-content-center">
           <label htmlFor="imageInput">
              <div className="imgCircleDiv">
                   {!selectedImage ? (
                         <span style={{ fontSize: '24px' }}>+</span>
                       ) : (
                         <Image src={selectedImage}
                           alt="profile"
                           style={{ width: '100%', height: '100%', borderRadius: '50%' }}/>
                        )}
                     </div>
                   </label>
                <input type="file" name="profImage"  ref={register({ required: true})} 
                   id="imageInput"
                   accept="image/*"
                   style={{ display: 'none' }}
                   onChange={handleImageChange}/>

             </div>

            <div className="col-md-12">

                    <div className="form-floating mt-3">
                            <input placeholder="" type="text" className="h-auto form-control" name="fullName" 
                            ref={register({ required: true})} />
                            {errors.fullName && <span className="alert alert-danger" role="alert">Full Name Required</span>}
                            <label for="floatingInput">Full Name</label>
                        </div>
                </div>

            <div className="col-md-12">
                <div className="row">
                        <div className="col-md">
                            <div className="form-floating mt-3">
                                <input placeholder="Phone Number" type="text" className="h-auto form-control " name="phoneNumber" 
                                    ref={register({ required: true })} />
                                    {errors.phoneNumber && <span className="alert alert-danger" role="alert">Phone Number Required</span>}
                                <label for="floatingInput">Phone Number</label>
                                </div>
                        </div>

                    <div className="col-md">
                    <div className="form-floating">
                        <select className="form-select mt-3" name="sex" ref={register({ required: true })}>
                            {/* <span className="mt-4">Select Sex</span> */}
                                <option value='male'>Male</option>
                                <option value='female'>Female</option>
                        </select>
                        <label for="floatingSelect">Sex</label>
                    </div>

                        </div>

                    </div>
            </div>

            <div className="col-md-12">
                <div className="form-floating mt-3">
                    <input placeholder="Email Address" type="text" className="h-auto form-control" name="email" ref={register({ required: true })} />
                        {errors.email && <span className="alert alert-danger" role="alert">Email Required</span>}
                        <label for="floatingInput">Email Address</label>
                    </div>

                    </div>

            <div className="col-md-12">
                <div className="form-floating mt-3">
                    <textarea  id="floatingTextarea2" className="form-control" placeholder="Type contact address here"  name="address" ref={register({ required: true })} style={{height: "100px"}}/>
                    {errors.address && <span className="alert alert-danger" role="alert">Contact address Required</span>}
                    <label for="floatingTextarea2">Contact Address</label>
                </div>
            </div>

            <div className="col-md-12">
                    <div className="form-floating mt-3">
                            <input placeholder="" type="text" className="h-auto form-control" name="accountName" 
                            ref={register({ required: true})} />
                            {errors.accountName && <span className="alert alert-danger" role="alert">Account Name Required</span>}
                            <label for="floatingInput">Account Name</label>
                        </div>
                </div>

            <div className="col-md-12">
               <div className="row">
                        <div className="col-md">
                            <div className="form-floating mt-3">
                                    <input placeholder="" type="text" className="h-auto form-control" name="accountNumber" 
                                    ref={register({ required: true})} />
                                    {errors.accountNumber && <span className="alert alert-danger" role="alert">Account Number Required</span>}
                                    <label for="floatingInput">Account Number</label>
                                </div>
                                </div>

                        <div className="col-md">
                            <div className="form-floating mt-3">
                                    <input placeholder="" type="text" className="h-auto form-control" name="bankName" 
                                    ref={register({ required: true})} />
                                    {errors.bankName && <span className="alert alert-danger" role="alert">Bank Name Required</span>}
                                    <label for="floatingInput">Bank Name</label>
                                </div>
                                </div>
                                
                        </div>
            </div>

            <div className="col-md-12">

                    <div className="form-floating mt-3">
                            <input placeholder="" type="text" className="h-auto form-control" name="username"
                            value={marketer.fullName} disabled/>
                            <label for="floatingInput">Referial Name</label>
                        </div>
                </div>
            

             <div className="mt-4 mb-4">

                <Button variant="primary"  className="float-end"  type="submit" disabled={isBtnLoading}>     
                {isBtnLoading ? (<>Waiting...</>) : (<>Submit Form</>)}
                </Button>
               </div>

         </div>

</form> 

                            
                         </div>
                    
                    <div class="col"></div>
                    
                </div>
                
	        </div>
        </div>



          </Layout>
            
        </>
    );
};

export default RealtorForm;